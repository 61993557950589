import React from "react";
import { useLocation } from "react-router-dom";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import "./Classes.css";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";

const ClassesDetails = () => {
    const location = useLocation();
    const item = location.state?.item;
    console.log(item, "itemDetails")
    return (
        <UserLayout>
            <ApplicationHeader
                title="Classes"
            />
            <div className="classesDetailsContainer">
                <div className="classesBox">
                    <p className="mb-0 font18 fw600 classNameColor">Class {item.name}</p>
                    <ApplicationButton
                        btnText={"Add Section"}
                        className={"button40"}
                    />
                </div>
                <p className="mb-0 font14 fw600 classNameColor mt-2">Subject: Hindi ,English, Maths</p>
                <p className="mb-0 font14 fw600 classNameColor mt-2">Session-12-04-24 To 30-03-25</p>
            </div>
        </UserLayout>
    )
}

export default ClassesDetails;